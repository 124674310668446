import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="simple-expand"
export default class extends Controller {
  connect() {
  }
  toggle(e) {
    this.element.classList.toggle('open')
  }
}
